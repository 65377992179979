@import './styles';

%overlay {
    @extend %open;
    @extend %closed;

    position: absolute;
    z-index: 85;
    display: flex;
    flex-wrap: wrap;
    background: $white;
    padding: 5px;
    border: 1px solid $gray12;
    border-radius: 10px;
}
@import './styles';

%button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: $button-width;
    height: $button-height;
    padding: 6px;
    font-size: 16px;
    cursor: pointer;

    img {
        width: 24px;
    }

    &.disable {
        cursor: not-allowed;
        color: $gray20;

        .Icon {
            color: $gray20;
        }

        &.down-arrow:after {
            border-top: 6px solid $gray20;
        }
    }

    &:hover:not(.disable) {
        background: #F6F6F6;
    }

    &.active:not(.disable) {
        background: #F0F0F0;

        .Icon {
            color: #757575;
        }
    }

    &.down-arrow:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #757575;
    }
}
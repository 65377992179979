// Dimensions:
$header-height: 59px;
$menu-width: 260px;
$top-bar-height: 47px;
$button-width: 46px;
$button-height: 46px;
$icon-width: 24px;
$icon-height: 24px;
$panel-width: 300px;
$right-panel-width: $panel-width;
$left-panel-width: $panel-width;

// Font
$font-family: sans-serif;

// Colors:
$border-color: #e6e6e6;
$gray87: #212121;
$gray54: #757575;
$gray20: #cdcdcd;
$gray12: #e0e0e0;
$gray10: #e6e6e6;
$gray4: #f5f5f5;
$white: #fff;
$link-color: #196186;
$link-hover-color: #196186;

%open {
    &.open {
        visibility: visible;
    }
}

%closed {
    &.closed {
        visibility: hidden;
    }
}
@import '../../constants/styles';

.agenda-list {
    .day-container {
        width: calc(100vw - #{$menu-width});

        > .day-title {
            font-size: 20px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.05;
            letter-spacing: normal;
            color: #000000;
            
            padding: 20px 0 0 40px;
        }

        > .agenda-container {
            padding: 20px 20px 20px 40px;
            display: flex;
            border-bottom: solid 1px #e6e6e6;
            cursor: pointer;
            text-decoration: none;

            > .agenda-time {
                width: 100px;
                position: relative;
                flex: 0 0 auto;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: -0.22px;
                color: #7f7f7f;
            
                margin-top: 2px;
            }

            > .agenda-summary {
                position: relative;
                flex: 1 1;

                > .agenda-title {
                    font-size: 18px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    color: #196186;
                }

                > .agenda-description {
                    font-size: 16px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    color: #000000;
                }
            }
        }
    }
}
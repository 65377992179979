@import '../../constants/overlay';

.SharedAnnotationsOverlay {
    @extend %overlay;

    top: 46px;

    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
}

@import '../../constants/styles';

.app-breadcrumb {
    height: $header-height;
    margin-left: 20px;
    display: flex;

    > .breadcrumb-item {
        margin: auto;
        display: flex;
        //height: $header-height;
        // line-height: $header-height;
        // vertical-align: middle;

        a, div {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #605e5c;
            text-decoration: none;
        }

        > div {
            float: left;

            &.link {
                cursor: pointer;
            }
        }

        &:first-child {
            a {
                font-weight: normal !important;
                color: #605e5c !important;
            }
        }

        &:last-child {
            a {
                font-weight: 600;
                color: #323130;
            }
        }

        > .seperator-container {
            display: flex;

            i {
                margin: auto 8px auto 4px;
                color: #605e5c;
            }
        }

        > .link-container {
            padding-top: 1px;
        }
    }
}
.agenda {
    padding: 40px;
    width: 100%;
    height: 100%;

    .header {
        > .details {
            padding-left: 72px;

            > .agenda-property {
                display: flex;
                margin: 10px 0;

                > .agenda-property-label {
                    width: 150px;
                    position: relative;
                    flex: 0 0 auto;
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    color: #7f7f7f;
                }

                > .agenda-property-value {
                    position: relative;
                    flex: 1 1;
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    color: #323130;
                }
            }
        }
    }

    .documents {
        margin-top: 48px;

        > .document-container {
            margin-left: -16px;
            padding-left: 88px;
            height: 40px;
            border-bottom: solid 1px #e6e6e6;
            line-height: 40px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #323130;

            &:hover {
                background-color: #e2eef1;
                border-radius: 8px;
                cursor: pointer;
            }

            > .ui-icon {
                margin-right: 18px;
            }

            > .annotation-container {
                margin-left: auto;
                margin-right: 88px;
                display: flex;

                > div {
                    &.icon {
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        margin-left: 8px;

                        &:nth-child(0) {
                            margin-left: 0;
                        }
                    }

                    &.annotation-icon {
                        background-image: url(../../images/annotate.png);
                    }

                    &.annotation-shared-icon {
                        background-image: url(../../images/annotate-shared.png);
                    }
                }
            }
        }
    }

    .agenda-items {
        margin-top: 48px;

        > .agenda-item-container {
            margin-left: 72px;

            > .title {
                min-height: 40px;
                font-size: 16px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.38;
                letter-spacing: normal;
                color: #196186;
                padding-bottom: 0;
                display: flex;
                align-items: center;
                cursor: pointer;

                > .title-inner {
                    margin-left: 10px;
                }

                > .ms-IconAgendaItem {
                    margin: 2px 5px 0 5px;

                    &:first-of-type {
                        margin-left:10px;
                    }
                }

                > .icon {
                    width: 22px;
                    height: 18px;
                    background-repeat: no-repeat;
                    background-size: 16px 16px;
                    background-position: center;
                    margin-left: 10px;
                    margin-top: 4px;
                }

                > .empty-note {
                    background-image: url(../../images/note.png);
                }

                > .has-note {
                    background-image: url(../../images/hasNote.png);
                }
            }

            > .agenda-item-content {
                > .description,
                > .minutes {
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    color: #323130;
                    margin: 10px 0 12px 0;
                }
    
                > .documents {
                    margin: 0 0 0 -72px;
                }

                > .minutes {
                    white-space: pre-line;

                    ol {
                        margin-left: 15px;
                    }

                    ul {
                        margin-left: 20px;
                    }
                }
            }
        }
    }


    .title {
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #323130;
        padding-bottom: 20px;
    }
}

.shared-note {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 10px;
    padding-top: 10px;

    &:first-of-type {
        padding-top: 0;
    }

    &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
    }

    .shared-note-user {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #7f7f7f;
    }
}
@mixin dimensions {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.app-container  {
    @include dimensions;
    display: flex;
}

.column {
    @include dimensions;
    flex: 3;
    display: flex;
    flex-direction: column;


    &.logo {
        flex: 2;
        background-color: #F0F7F9;

        > img {
            align-self: flex-end;
            width: 100%;
        }
    
        .header {
            flex-grow: 1;
            display: flex;
    
            img {
                max-width: 100%;
                margin: auto;
            }
        }
    }

    &.info {
        background-color: #fff;

        .header {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            margin: 50px;

            h1 {
                color: #00A1A4;
            }
            
            p {
                font-size: 1.15em;
                flex: 1;
                display: flex;
                flex-flow: column;
                justify-content: center;

                &:first-of-type {
                    justify-content: flex-end;
                }

                &.error-message {
                    color: #ff0000;
                    margin: auto 0 0 0;
                }
            }

            > .button-container {
                max-height: 200px;
                align-self: center;

                button {
                    padding: 20px;
                    background-color: #00A1A4;
                    color: #fff;
                    font-weight: 600;
                    font-size: 1.4em;
                }
            }
        }
    }
}
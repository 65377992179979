@import '../../constants/button';

$itemHeight: 20px;
$itemWidth: 20px;

.SelectionButton {
    @extend %button;
    justify-content: left;
    width: 100%;
    margin: 0;

    .user-color {
        height: $itemHeight;
        width: $itemWidth;
        margin-right: 3px;
    }

    .ui-checkbox {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        align-items: flex-end;
        padding: 0;
        margin-left: 10px;
    }
}

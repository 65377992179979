.menu {
    > .backButtonContainer {
        cursor: pointer;

        .ui-button {
            height: 3.2rem;
            width: 3.2rem;
        }

        > .backButtonText {
            margin-left: -10px;
            line-height: 3.2rem;
            vertical-align: middle;
            display: inline-block;
        }
    }

    > .menuList {
        list-style-type: none;
        margin: 0 8px;

        > .menuItem {
            cursor: pointer;

            > div {
                font-size: 14px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                color: #323130;

                &:not(.document) {
                    display: flex;
                    min-height: 42px;
                }

                &.date {
                    font-weight: normal;

                    > div {
                        margin: auto 32px;
                    }
                }

                &.agendaitem {
                    color: #196186;

                    &:hover {
                        background-color: transparent;
                        border-radius: 0;
                        cursor: initial;
                    }
                }

                &.document {
                    font-weight: normal;

                    > div {
                        display: flex;
                        align-items: center;
                        word-break: break-all;
                        height: 42px;
                        max-height: 42px;

                        > .ui-icon {
                            margin-right: 8px;
                        }

                        > span {
                            word-break: break-all;

                            &.document-title {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                flex: 1;
                            }
                        }

                        > .annotation-icon {
                            background-image: url(../../images/annotate.png);
                            width: 20px;
                            height: 20px;
                            margin-left: 8px;
                        }

                        > .annotation-shared-icon {
                            background-image: url(../../images/annotate-shared.png);
                            width: 20px;
                            height: 20px;
                            margin-left: 8px;
                        }
                    }
                }

                &.active,
                &:hover {
                    background-color: #e2eef1;
                    border-radius: 8px;
                }

                > div {
                    flex: 1;
                    display: flex;
                    margin: auto 0px auto 8px;

                    &.has-subtitle {
                        flex-flow: column;

                        .subtitle {
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }
}